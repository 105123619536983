
import { Component, Vue } from 'vue-property-decorator'
import { TimingPlan } from '@/types/irrigation'
import { timeIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationTimingDetail extends Vue {
  private info: TimingPlan = {
    planName: '',
    projectAreaName: '',
    projectId: '',
    dateRange: [],
    executeTime: '',

    concurrentNum: '',
    intervalDays: '',
    keepLength: ''
  }

  get timeIrrigationDesc () {
    return timeIrrigationDesc
  }

  created () {
    this.getPlanDetail(this.$route.params.id)
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.irrigation.irrigationPlanDetail, {
      planId: id
    }).then((res) => {
      this.info = {
        planId: res.planId,
        planName: res.planName,
        projectAreaName: res.projectAreaName,
        projectId: res.projectId,
        startDate: res.startDate,
        endDate: res.endDate,
        executeTime: res.executeTime,

        dateRange: [],
        concurrentNum: res.concurrentNum,
        intervalDays: res.intervalDays,
        keepLength: res.keepLength
      }
    })
  }
}
